<template>
    <b-navbar toggleable="sm" :variant="variant" fixed="top" id="header-nav">
        <b-navbar-nav>
            <b-navbar-brand class="name d-none d-md-inline">
                {{ name }}
                <b-nav-text class="age" v-if="variant=='primary'">Klassenstufe {{ age }}</b-nav-text>
                <b-nav-text class="age" v-else>Testquiz {{ age }}. Klasse</b-nav-text>
            </b-navbar-brand>
        </b-navbar-nav>

        <b-navbar-nav class="m-auto text-center" v-if="started">
            <b-nav-text class="d-none d-md-inline">
                <b-button :variant="variant" :disabled=true>{{ answered }} von {{ total }} Fragen beantwortet</b-button>
            </b-nav-text>

            <b-nav-text v-if="points == -1">
                <b-button :variant="variant" v-on:click="$emit('sendResult')">Ergebnis absenden</b-button>
            </b-nav-text>
        </b-navbar-nav>

        <b-navbar-nav  class="ml-auto">
            <b-nav-text id="clock">
                <font-awesome-icon icon="clock" v-on:click="forceClock"></font-awesome-icon>
                <span :style="this.clockDisplay">&nbsp;{{ clock }}</span>
            </b-nav-text>
        </b-navbar-nav>
    </b-navbar>
</template>
<script>
import moment from 'moment'
export default {
  name: 'QuizHeader',
  props: ['name', 'age', 'started', 'total', 'answered', 'points', 'time', 'variant'],

  data: function () {
    return {
      forcedClock: 0
    }
  },

  watch: {
    time: function () {
      if (this.forcedClock > 0) {
        this.forcedClock--
      }
    }
  },

  computed: {
    clockDisplay: function () {
        if (this.time <= 600 || this.time > 2690 || this.forcedClock > 0) {
          return 'display: inline;';
        }

        return 'display: none;';
    },

    clock: function () {
      let dur = moment.duration(this.time, 'seconds')

      let min = dur.minutes()
      let sec = dur.seconds()

      return min + ':' + (sec < 10 ? ('0' + sec) : sec)
    },
  },

  methods: {
    forceClock: function () {
      if (this.forcedClock > 0) {
        this.forcedClock = 0;
      } else {
        this.forcedClock = 5;
      }
    }
  }
}
</script>
<style>
#header-nav .name {
    color: #FFF;
    font-size: 1.2em;
    padding: 0;
}
#header-nav .age {
    color: #FFF;
    font-size: 0.8em;
    padding: 0;
}
#header-nav #clock {
    color: #FFF;
}

</style>
