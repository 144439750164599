import axios from 'axios'
// import router from '../router'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

/**
api.interceptors.request.use((config) => {
  let json = localStorage.getItem('auth')
  if (json === null) {
    return config
  }

  try {
    let auth = JSON.parse(json)
    config.headers['Authorization'] = auth.token_type + ' ' + auth.access_token
  } catch (e) {
    //
  }

  return config
})


api.interceptors.response.use(
  function (response) {
    return response
  },

  function (error) {
    if (error.response && error.response.status === 401) {
      router.push({name: 'login', params: {from: 'request'}})
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)
*/

export default api
