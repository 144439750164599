<template>
    <div>
        <b-row class="justify-content-center">
            <h5>Ist alles richtig?</h5>
        </b-row>


        <b-row class="justify-content-center">
            <b-col cols="12" md="8">
                <b-card class="mb-3">
                    <b-row class="justify-content-md-center">
                        <b-col cols="12"><b>Dein Name:</b></b-col>
                        <b-col cols="12">{{ name }}</b-col>
                    </b-row>

                    <b-row class="justify-content-md-center">
                        <b-col cols="12"><b>Deine Schule:</b></b-col>
                        <b-col cols="12">{{ school }}</b-col>
                    </b-row>

                    <b-row class="justify-content-md-center">
                        <b-col cols="12"><b>Deine Klassenstufe:</b></b-col>
                        <b-col cols="12">{{ age }}</b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="text-left" cols="6" md="4" offset-md="2">
                <b-button type="submit" size="lg" variant="secondary" class="mr-3" v-on:click="$emit('confirm', false)">nein</b-button>
            </b-col>

            <b-col class="text-right" cols="6" md="4">
                <b-button type="submit" size="lg" variant="primary" v-on:click="$emit('confirm', true)">ja</b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
  export default {
    name: 'Confirm',
    props: ['name', 'age', 'school']
  }
</script>
<style>
button {
    min-width: 100px;
}
</style>