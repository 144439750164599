<template>
    <form>
        <b-row class="justify-content-md-center">
            <h5>In welcher Klassenstufe bist du?</h5>
        </b-row>

        <b-row class="justify-content-center">
            <b-col cols="12" md="8">
                <b-form-group>
                    <b-form-input v-on:input="selectAge" size="lg" placeholder="Deine Klassenstufe" autocomplete="off" autofocus></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="text-left" cols="6" md="4" offset-md="2">
                <b-button type="button" size="lg" variant="secondary" v-on:click="$emit('back')">zurück</b-button>
            </b-col>

            <b-col class="text-right" cols="6" md="4">
                <b-button type="button" size="lg" variant="primary" :disabled="!this.selectedAge" v-on:click="$emit('next')">weiter</b-button>
            </b-col>
        </b-row>
    </form>
</template>
<script>
  export default {
    name: 'LoginAge',
    props: ['age'],
    data: function () {
      return {
        selectedAge: '',
        options: [3, 4, 5, 6, 7, 8]
      }
    },

    mounted: function () {
      if (this.age) {
        this.selectAge(this.age)
      }
    },

    methods: {
      selectAge: function (age) {
        age = age.trim()

        if (!age) {
          this.$emit('hideError')
          return
        }

        if (this.options.indexOf(parseInt(age, 10)) === -1) {
          this.$emit('error', 'Bitte gib Deine Klassenstufe (von 3 bis 8) an')
          return
        }

        this.$emit('hideError')

        this.selectedAge = age
        this.$emit('age', age)
      }
    }
  }
</script>
