<template>
    <div class="quiz">
        <nav-header
                v-bind:started="quiz.started"
                v-bind:name="user.name"
                v-bind:age="user.age"
                v-bind:total="total"
                v-bind:answered="answered"
                v-bind:points="user.points"
                v-bind:time="quiz.time.left"
                v-bind:variant="variant"
                v-on:sendResult="resultModal">
        </nav-header>

        <b-alert variant="danger" :show="error.show" style="position: fixed; z-index: 1000; top: 70px; left: 0; right: 0;">{{ error.message }}</b-alert>

        <modal
            ref="resultModal"
            v-bind:started="quiz.started"
            v-bind:points="user.points"
            v-bind:answers="answers"
            v-bind:total="total"
            v-bind:answered="answered"
            v-on:confirmed="sendResults">
        </modal>



        <div class="quiz-body">
            <start
                v-if="!quiz.started"
                v-bind:starting="starting"
                v-on:start="start"
                v-on:resetLogin="resetLogin">
            </start>

            <ended
                v-if="quiz.ended"
                :points="user.points"
                :quiz="quiz"
                v-on:resetLogin="resetLogin">
            </ended>

            <question
                    v-if="question()"
                    v-bind:question="question()"
                    v-bind:answer="answer()"
                    v-bind:currentQuestion="quiz.currentQuestion"
                    v-bind:variant="variant"
                    v-on:selected="selectAnswer"
                    v-on:next="next"
                    v-on:back="back">
            </question>
        </div>

        <nav-footer
            v-if="question()"
            v-bind:categories="categories"
            v-bind:questions="questions"
            v-bind:answers="answers"
            v-bind:questionId="question().id"
            v-on:goto="goto">
        </nav-footer>
    </div>
</template>

<script>
import Header from '@/components/quiz/Header'
import Footer from '@/components/quiz/Footer'
import Question from '@/components/quiz/Question'
import Start from '@/components/quiz/Start'
import Ended from '@/components/quiz/Ended'
import Modal from '@/components/quiz/Modal'
import Storage from '@/mixins/storage'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
library.add(faClock)

export default {
    name: 'Quiz',
    mixins: [Storage],

    components: {
      NavHeader: Header,
      NavFooter: Footer,
      Question: Question,
      Modal: Modal,
      Start: Start,
      Ended: Ended,
    },

    data: function () {
      return {
        interval: null,
        starting: false,
        variant: 'primary',
        questions: [],
        error: {
          show: false,
          message: 'Fehler!'
        }
      }
    },

    mounted: function () {
      this.loadFromStorage('user')

      if (localStorage.getItem('token') === null) {
        this.$router.push({name: 'home'})
      }

      this.loadFromStorage('quiz')
      this.loadFromStorage('answers')
      this.loadFromStorage('questions')

      if (!this.quiz.started || this.quiz.ended) {
        return
      }

      this.loadFromStorage('categories')

      if (this.quiz.started) {
        if (this.questions.length === 0) {
          this.loadQuestions()
        }

        if (!this.quiz.ended) {
          this.$set(this, 'interval', setInterval(this.countDown, 1000))
        }
      }
    },

    computed: {
      total: function () {
        const questions = this.questions
        return questions.length
      },

      answered: function () {
        return Object.keys(this.answers).length
      }
    },

    methods: {
      answer: function () {
        let q = this.question()
        if (q === null) {
          return null
        }

        return this.answers[q.id]
      },


      question: function () {
        if (this.questions.length < 1) {
          return null
        }

        if (!this.quiz.started) {
          return null
        }

        if (this.quiz.ended) {
          return null
        }

        if (this.quiz.time.left <= 0) {
          return null
        }

        return this.questions[this.quiz.currentQuestion]
      },

      categoryId: function () {
        if (this.categories.length < 1) {
          return null
        }


        if (this.categories[this.quiz.currentCategory]) {
          return this.categories[this.quiz.currentCategory].id
        }

        return null
      },

      selectAnswer: function (selection) {
        let questionId = this.questions[this.quiz.currentQuestion].id

        if (this.answers[questionId] === selection) {
          this.$delete(this.answers, questionId)
        } else {
          this.$set(this.answers, questionId, selection)
        }

        this.persistToStorage('answers')
      },

      next: function () {
        if (this.quiz.currentQuestion < this.questions.length - 1) {
          this.quiz.currentQuestion++
          return
        }

        this.resultModal()
      },

      back: function () {
        if (this.quiz.currentQuestion > 0) {
          this.quiz.currentQuestion--
        }
      },

      goto: function (index) {
        this.quiz.currentQuestion = index
      },

      resultModal: function () {
        this.error.show = false
        this.$refs.resultModal.show()
      },

      resetLogin: function () {
        localStorage.clear()
        this.$router.push({name: 'home'})
      },

      start: function () {
        if (this.starting) {
          return
        }

        this.starting = true

        let body = {
          token: localStorage.getItem('token'),
          name: this.user.name,
          age: parseInt(this.user.age),
        }

        this.axios.post('/quiz/start', JSON.stringify(body))
            .then((rsp) => {
              let meta = rsp.data.meta
              let data = rsp.data.data

              this.setRequestToken(data.token)

              this.quiz.startedAt = meta.serverTime
              this.quiz.time.total = meta.availableTime
              this.quiz.time.left = meta.availableTime
              this.quiz.test = data.testQuiz
              this.categories = rsp.data.categories

              this.variant = 'primary'
              if (data.testQuiz) {
                // this.variant = 'danger'
              }

              this.persistToStorage('categories')

              this.user.requestToken = data.token
              this.loadQuestions()

              this.$set(this.quiz, 'started', true)
              this.error.show = false

              this.$set(this, 'interval', setInterval(this.countDown, 1000));
            })

            .catch((err) => {
              this.alert(err)
            })

            .finally(() => {
              this.starting = false
            })
      },

      sendResults() {
        this.axios({
          method: 'post',
          url: '/quiz/result',
          headers: {Authorization: this.getRequestToken()},
          data: this.answers,
        })

        .then((rsp) => {
          this.$set(this.user, 'points', rsp.data.points)
          this.$set(this.quiz, 'ended', true)
          this.error.show = false
          clearInterval(this.interval)
        })

        .catch((e) => {
          if (!e.response || !e.response.status) {
            this.showAlert('Keine Internetverbindung, das Ergebnis konnte nicht gesendet werden.')
            return
          }


          this.showAlert('Unbekannter Fehler, das Ergebnis konnte nicht gesendet werden.')
        })

        .finally(() => {
          this.$refs.resultModal.hide()
        })
      },

      countDown: function () {
        if (!this.quiz.started || this.quiz.ended) {
          return
        }

        if (this.quiz.time.left <= 0) {
          this.sendResults()
          clearInterval(this.interval)
          return
        }

        this.quiz.time.left--
      },

      loadQuestions: function () {
        let token = this.getRequestToken()
        if (!token) {
          return
        }

        this.axios({
          method: 'get',
          url: '/quiz/questions',
          headers: {Authorization: token}
        }).then((rsp) => {
          let selectedAge = 0

          try {
            selectedAge = parseInt(localStorage.getItem('age'))
          } catch (e) {
            this.$raven.captureException(e)
          }

          let tempQuestions = []

          rsp.data.forEach((q) => {
            if (q.ageGroup !== selectedAge && q.ageGroup + 1 !== selectedAge) {
                this.$raven.captureMessage('wrong age group', null, this.loadFromStorage('user'))
                this.resetLogin()
            }

            tempQuestions.push(q)
          })

          this.$set(this, 'questions', this.shuffleQuestions(tempQuestions))
          this.persistToStorage('questions')
        })
      },

      shuffleQuestions: function (array) {
        // completely random
        for (let i = array.length - 1; i > 0; i--) {
          let j = Math.floor(Math.random() * (i + 1));
          let temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }

        // by category
        let byCategory = []
        this.categories.forEach((cat) => {
          array.forEach((question) => {
            if (question.categoryId != cat.id) {
              return
            }

            byCategory.push(question)
          })
        })

        return byCategory
      },

      showAlert: function (error) {
        this.error.message = this.getErrorMessage(error)
        this.error.show = true

        try {
          window.scrollTo(0, 0)
        } catch (e) {
          this.$raven.captureException(e)
        }
      },

      getErrorMessage: function (error) {
        if (typeof error === 'string') {
          return error
        }

        // default error message
        let message = 'Ein unbekannter Fehler ist aufgetreten'

        // try to extract error from response
        if (error.response && error.response.data && error.response.data.error) {
          let errorData = error.response.data.error
          if (errorData.translations && errorData.translations['de']) {
            message = errorData.translations['de']
          }
        }

        return message
      }
    }
}
</script>
<style>
.quiz-body {
    margin-top: 130px;
    padding-bottom: 150px;
}

</style>
