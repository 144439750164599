<template>
  <div class="home">
    <b-alert v-if="localStorageSupported !== true" variant="danger" show>
        <h4 class="alert-heading text-center">Nicht unterstützter Browser!</h4>
        <p>Dein Browser unterstützt den local storage nicht. Das ist vor allem bei älteren Versionen von Safari im privaten Modus der Fall.</p>
        <p>Bitte versuche den privaten Modus zu deaktivieren oder nutze einen anderen Browser.</p>
    </b-alert>

    <login
        v-else-if="(currentCompetition && currentCompetition.online) || this.trial"
        v-bind:trial="trial">
    </login>

    <div v-else>
        <h3>Willkommen beim HEUREKA! Schülerwettbewerb</h3>
        <p v-if="currentCompetition && currentCompetition.start > Date.now()">
            Der nächste Wettbewerb "{{ this.currentCompetition.name }}" startet am {{ this.currentCompetition.start | moment('LL') }}
        </p>

        <p v-else-if="currentCompetition && currentCompetition.end < Date.now()">
            Der Wettbewerb "{{ this.currentCompetition.name }}" ist beendet.
        </p>
    </div>
  </div>
</template>

<script>
import Login from '@/components/Login'
const moment = require('moment');

export default {
  name: 'Home',
  components: {
    'login': Login
  },

  data: function() {
    return {
      currentCompetition: null,
      localStorageSupported: false,
      trial: ''
    }
  },

  mounted: function () {
    this.checkLocalStorage()
    this.checkTrialRoute()

    let jsonUser = localStorage.getItem('user')
    if (jsonUser !== null) {
        try {
          let user = JSON.parse(jsonUser)
          if (user.requestToken) {
            this.$router.push({name: 'quiz', props: {user: user}})
          }
        } catch( err ) {
            // err
        }
    }

    this.loadCurrentCompetition()
  },

  methods: {
    checkLocalStorage () {
        try {
          localStorage.setItem('test', '123')
          localStorage.removeItem('test')

          this.localStorageSupported = true
        } catch (e) {
            //
        }
    },

    checkTrialRoute: function () {
        if (this.$route.name !== 'testquiz') {
          return
        }

        this.$set(this, 'trial', this.$route.params['token'])
    },

    loadCurrentCompetition: function () {
      this.axios.get('/competitions/current')
          .then((rsp) => {
            let data = rsp.data

            if (data == null) {
              return
            }

            let now = moment()
            let start = moment(data.dates.online.start).startOf('day')
            let end = moment(data.dates.online.end).endOf('day')

            this.$set(this, 'currentCompetition', {
              id: data.id,
              name:data.name,
              start: start,
              end: end,
              online: (start < now && end > now),
              loaded: Date.now()
            })
          })
    }
  }
}
</script>
<style>
div.home {
    padding: 2em;
}
</style>
