<template>
    <div id="question-nav" class="d-none d-md-block">
        <b-row class="categories">
            <b-col v-for="category in categories" :key="category.id" cols="4"><strong>{{ category.name }}</strong></b-col>
        </b-row>

        <b-row>
            <b-col v-for="(category, categoryIndex) in categories" :key="category.id" cols="4" class="p-0">
                <span
                    v-for="(question, questionIndex) in categoryQuestions(category.id)"
                    :key="question.id"
                    v-on:click="$emit('goto', (categoryIndex * 15) + questionIndex)"
                    :class="classes(question.id)"
                >{{ (categoryIndex * 15) + questionIndex + 1 }}</span>
            </b-col>
        </b-row>
    </div>

</template>
<script>
export default {
  name: 'QuizFooter',
  props: ['questionId', 'categories', 'questions', 'answers'],

  methods: {
    categoryQuestions: function (categoryId) {
      return this.questions.filter(question => question.categoryId === categoryId)
    },

    classes: function (questionId) {
      let classes = 'bc'

      if (questionId === this.questionId) {
        classes += ' current'
      }

      if (this.answers && this.answers[questionId]) {
        classes += ' answered'
      }

      return classes
    }
  }
}
</script>
<style>
#question-nav {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;

    padding: 0;
    border-top: 1px solid #CCC;
    background-color: #F4F4F4;
}
#question-nav .col-4 {
    padding: 0 !important;
}
span.bc {
    font-size: 1.1em;
    margin-right: 0.1em;
    cursor: pointer;
    color: #333;
    display: inline-block;
    padding: 0 0.3em;
}
span.bc.current {
    font-weight: bold;
    cursor: pointer;
    color: #007bff;
}
span.bc.answered {
    color: #FFF;
    cursor: pointer;
    background-color: #007bff;
}

div.category_0 {

}
</style>
