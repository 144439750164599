import Vue from 'vue'
import App from './App.vue'
import VueSentry from 'vue2-sentry'
import router from './router'
import api from './lib/api'


// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

Vue.use(VueSentry, {
  key: 'ff8a05e5cb1842dab33374aeb5efe51e',
  project: '1310795',
})

Vue.config.productionTip = false
Vue.prototype.axios = api

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)

const moment = require('moment')
require('moment/locale/de')
Vue.use(require('vue-moment'), {
    moment
})


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
