<template>
    <b-modal ref="resultModal" title="Ergebnisse senden">
        <p class="text-center">
            Du bist bei der letzten Aufgabe angekommen und hast {{ answered }} von {{ total }} Fragen beantwortet.
            Möchtest du jetzt das Quiz beenden und die Ergebnisse absenden?
            Du kannst dann keine Fragen mehr beantworten!
        </p>
        <template slot="modal-footer">
            <b-btn class="mt-3" variant="danger" block @click="$emit('confirmed')">Ja, jetzt senden</b-btn>
            <b-btn class="mt-3" variant="secondary" block @click="this.hide">Nein, noch nicht</b-btn>
        </template>
    </b-modal>
</template>
<script>
  export default {
    name: 'ResultModal',
    props: ['total', 'answered'],

    methods: {
      show: function () {
        this.$refs.resultModal.show()
      },

      hide () {
        this.$refs.resultModal.hide()
      }
    }
  }
</script>
