import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Quiz from './views/Quiz.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/testquiz/:token',
      name: 'testquiz',
      component: Home
    },
    {
      path: '/quiz',
      name: 'quiz',
      component: Quiz
    }
  ]
})
