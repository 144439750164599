export default {
  data: function () {
    return {
      requestToken: null,
      token: null,
      quiz: {
        id: null,
        started: false,
        ended: false,
        startedAt: null,
        time: {
          total: 2700,
          left: 2700,
        },
        currentCategory: 0,
        currentQuestion: 0,
        answers: {}
      },
      answers: {},
      questions: [],
      categories: [],
      user: {
        requestToken: null,
        name: '',
        age: 0,
        points: -1,
      }
    }
  },

  watch: {
    quiz: {
      handler (quiz) {
        localStorage.setItem('quiz', JSON.stringify(quiz))
      },
      deep: true,
    },

    user: {
      handler (user) {
        localStorage.setItem('user', JSON.stringify(user))
      },
      deep: true,
    }
  },

  methods: {
    persistToStorage: function (storageKey) {
      if (this[storageKey] === undefined) {
        this.$raven.captureMessage('Can not persist unknown key ' + storageKey)
        return
      }

      try {
        let json = JSON.stringify(this[storageKey])
        localStorage.setItem(storageKey, json)
      } catch (e) {
        this.$raven.captureException(e)
      }
    },

    loadFromStorage: function (storageKey) {
      let json = localStorage.getItem(storageKey)
      if (json == null) {
        return
      }

      try {
        let parsed = JSON.parse(json)
        for (let key in parsed) {
          if (!Object.prototype.hasOwnProperty.call(parsed, key)) {
            continue
          }

          this.$set(this[storageKey], key, parsed[key])
        }
      } catch(e) {
        return
      }
    },

    getRequestToken: function () {
      if (typeof this.requestToken === 'string') {
        return this.requestToken
      }

      let stored = localStorage.getItem('requestToken')
      if (typeof stored === 'string') {
        this.$set(this, 'requestToken', stored)
      }

      return stored
    },

    setRequestToken: function (token) {
      this.$set(this, 'requestToken', token)
      localStorage.setItem('requestToken', this.requestToken)
    }
  }
}
