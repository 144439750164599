<template>
    <div>
        <b-row class="justify-content-center">
            <h5>Wie ist dein Name?</h5>
        </b-row>

        <b-row class="justify-content-center">
            <b-col cols="12" md="8">
                <b-form-group>
                    <b-form-input type="text" size="lg" v-model="firstName" placeholder="Vorname" autocomplete="off" autofocus style="text-align:center"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="justify-content-center">
            <b-col cols="12" md="8">
                <b-form-group>
                    <b-form-input type="text" size="lg" v-model="lastName" placeholder="Nachname" autocomplete="off" style="text-align:center"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-lg-left" cols="6" md="4" offset-md="2">
                <b-button type="button" size="lg" variant="secondary" v-on:click="$emit('back')">zurück</b-button>
            </b-col>

            <b-col class="text-lg-right" cols="6" md="4">
                <b-button type="button" size="lg" variant="primary" v-on:click="validate">weiter</b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
  export default {
    name: 'LoginName',
    props: ['name'],

    data: function () {
        return {
            firstName: '',
            lastName: '',
        }
    },

    mounted: function () {
      if (!this.name) {
        return
      }

      let names = this.name.split(' ')
      this.firstName = names[0]
      this.lastName = names[names.length - 1]
    },

    watch: {
      fullName: function () {
        // hides error message
        this.$emit('change')
      }
    },

    computed: {
      fullName: function () {
        return this.firstName + ' ' + this.lastName
      }
    },

    methods: {
      validate: function () {
        this.firstName = this.firstName.trim()
        this.lastName = this.lastName.trim()

        if (this.firstName == '') {
          this.$emit('error', 'Bitte trage Deinen Vornamen ein')
          return
        }

        if (this.lastName == '') {
          this.$emit('error', 'Bitte trage Deinen Nachnamen ein')
          return
        }

        this.$emit('name', this.fullName)
      }
    }

  }
</script>
