<template>
    <b-row class="mt-5 justify-content-center">
      <b-col>
        <div v-if="quiz.test">
          <h3>Super, Du hast {{ points }} Punkte erreicht!</h3>
          <p v-if="quiz.time.left <= 0">Die Zeit ist nun abgelaufen. </p>
        </div>

        <div v-else>
          <h3>Vielen Dank für Deine Teilnahme!</h3>
          <p>
            <span v-if="quiz.time.left <= 0">Die Zeit ist nun abgelaufen. </span>
            Wir drücken Dir die Daumen und hoffen, es hat Dir Spaß bereitet.
          </p>
        </div>

        <b-btn class="mt-3" variant="danger" @click="$emit('resetLogin')">Beenden</b-btn>
      </b-col>
    </b-row>
</template>
<script>
export default {
  name: 'QuizEnded',
  props: ['quiz', 'points']
}
</script>
