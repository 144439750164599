<template>
    <b-card>

        <b-row class="question">
            <b-col cols="12" class="p-1 mb-3 align-middle p-3">
                    {{ question.question }}
                    <img class="image" v-if="question.image" :src="'//' + question.imagePath + '/' +question.id + '/' + question.image" blank-color="#ccc" alt="placeholder" />
            </b-col>
        </b-row>

        <div slot="footer">

            <b-row v-for="a in answers" v-bind:key="a.key" class="answers justify-content-md-center">
                <b-col cols="12" class="p-1">
                    <div
                        v-on:click="$emit('selected', a.key)"
                        :class="a.key === answer ? 'answer selected' : 'answer'"
                    >
                        {{ a.text }}
                    </div>
                </b-col>
            </b-row>

            <b-row class="buttons">
                <b-col cols=6 class="text-left pt-3">
                    <b-button type="button" v-on:click="$emit('back')">zurück</b-button>
                </b-col>
                <b-col cols=6 class="text-right pt-3">
                    <b-button :variant="variant" v-on:click="$emit('next')">weiter</b-button>
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>
<script>
export default {
  name: 'QuizQuestion',
  props: ['question', 'currentQuestion', 'answer', 'variant'],

  data: function () {
    return {
      selectedKey: null
    }
  },

  computed: {
    answers: function () {
      let keys = Object.keys(this.question.answers)
      
      // completely random
      for (let i = keys.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = keys[i];
        keys[i] = keys[j];
        keys[j] = temp;
      }

      let out = []
      keys.forEach((key) => {
        out.push({
          key: key,
          text: this.question.answers[key]
        })
      })

      return out
    }
  }
}
</script>
<style>
    div.question {
        width: 100%;
        font-size: 1.1em;
    }
    div.spacer {
        border-top: 1px solid #CCC;
    }
    div.answer {
        width: 100%;
        padding: 10px;
        background-color: #CCC;
        border-radius: 5px;
        cursor: pointer;
    }
    div.answer.selected {
        background-color: #2a93ff !important;
    }
    div.question img {
        max-width: 20%;
        float: right;
    }
</style>