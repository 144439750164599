<template>
    <div id="login-wizard">
        <b-alert variant="danger" v-bind:show="this.alert.show" class="fixed-top"><strong>Fehler:</strong> {{ this.alert.message }}</b-alert>
        <token
            v-if="this.step === 0"
            v-on:token="validate"
            v-on:change="hideAlert"
            v-bind:trial="trial"
        ></token>

        <name
            v-else-if="this.step === 1"
            v-bind:name="this.form.name"
            v-on:name="setName"
            v-on:error="this.showAlert"
            v-on:change="hideAlert"
            v-on:back="back"
        ></name>

        <age
            v-else-if="this.step === 2"
            v-bind:age="this.form.age"
            v-on:age="setAge"
            v-on:error="this.showAlert"
            v-on:hideError="this.hideAlert"
            v-on:next="next"
            v-on:back="back"
        ></age>

        <confirm
            v-if="this.step === 3"
            v-bind:name="this.form.name"
            v-bind:age="this.form.age"
            v-bind:school="this.form.school"
            v-on:confirm="confirm"
        ></confirm>
    </div>
</template>
<script>
import Token from '@/components/login/Token'
import Name from '@/components/login/Name'
import Age from '@/components/login/Age'
import Confirm from '@/components/login/Confirm'

export default {
  name: 'Login',
  props: ['trial'],

  components: {
    Token: Token,
    Name: Name,
    Age: Age,
    Confirm: Confirm
  },

  data: function () {
    return {
      step: 0,
      form: {
        name: null,
        age: null,
        school: null,
        token: null,
      },
      alert: {
        show: false,
        message: ''
      }
    }
  },

  methods: {
    next: function () {
      this.step++
    },

    back: function () {
        this.step--
    },

    setName: function (name) {
      this.form.name = name.trim()
      this.step++
    },

    setAge: function (age) {
      this.form.age = age
    },

    confirm: function (value) {
      if (!value) {
        this.step = 1
        return
      }

      localStorage.setItem('token', this.form.token)
      localStorage.setItem('name', this.form.name)
      localStorage.setItem('age', this.form.age)

      localStorage.setItem('user',
        JSON.stringify({
            name: this.form.name,
            age: this.form.age
        })
      )

      this.$router.push({name: 'quiz'})
    },

    showAlert: function (err) {
      if (typeof err === 'string') {
        this.alert.message = err
      } else if (err.response === undefined) {
        this.alert.message = 'Keine Verbindung zum Server möglich. Bitte prüfen Sie die Internetverbindung.'
      } else {
        let error = err.response.data.error
        this.alert.message = error.message

        if (error['translations'] && error['translations']["de"]) {
          this.alert.message = error['translations']["de"]
        }
      }

      this.alert.show = true
    },

    hideAlert: function () {
      this.alert.show = false
    },

    validate: function (token) {

      this.axios.post('/quiz/validate', JSON.stringify({token: token}))
          .then((rsp) => {
            let data = rsp.data

            this.form.token = token
            this.form.school = data.school.name

            this.hideAlert()
            this.step++
          })

          .catch((err) => {
            this.showAlert(err)
          })
    }
  }
}
</script>
<style>
#login-wizard {
    margin-top: 50px;
}
</style>
