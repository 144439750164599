<template>
    <div>
        <b-row class="justify-content-center">
            <b-col cols="3">
                <b-button v-on:click="$emit('start')" variant="primary" size="lg" :disabled="starting">Quiz starten</b-button>
            </b-col>
        </b-row>
        <b-row class="justify-content-center">
            <b-col cols="3">
                <b-button v-on:click="$emit('resetLogin')" variant="secondary" size="sm" class="mt-3">Neu anmelden</b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
  name: 'QuizStart',
  props: ['starting']
}
</script>