<template>
    <div>
        <h3>Willkommen beim HEUREKA! Wettbewerb</h3>
        <b-row class="justify-content-md-center">
            <b-col>
                <p>
                    Ihr bekommt gleich 45 Fragen in 3 Kategorien. Auf der Leiste, die ihr unten sehen werdet, könnt ihr direkt auf andere Fragen springen. Bereits beantwortete Fragen sind dabei blau markiert.
                </p>
                <p>
                    Von den drei vorgegebenen Antworten gibt es immer nur eine richtige Lösung.
                </p>
                <p>
                    Für jede richtig beantwortete Frage bekommt ihr drei Punkte, für jede falsch beantwortete Frage wird ein Punkt abgezogen. Für eine nicht beantwortete Frage gibt es keinen Abzug, aber natürlich auch keine Punkte. Zu Beginn erhaltet ihr ein „Startkapital“ von 45 Punkten, so dass ihr insgesamt zwischen 0 und 180 Punkte erreichen könnt.
                </p>
                <p>
                    Nachdem ihr euren Namen und die Klassenstufe eingetragen habt, läuft die Zeit. Mit einem Klick auf die kleine Uhr in der rechten oberen Ecke seht ihr, wie viel Zeit ihr noch habt. In den letzten 10 Minuten bleibt die Uhr dauerhaft sichtbar. Nach 45 Minuten wird der Wettbewerb von alleine beendet, aber ihr könnt ihn auch jederzeit früher beenden.
                </p>
                <p>
                    Dies ist ein Einzelwettbewerb. Ihr müsst alle Fragen ganz alleine und ohne Hilfen bearbeiten. Aber seid nicht traurig, wenn Ihr was nicht wisst. Auch wir, die die Fragen machen, könnten ohne Hilfe nicht immer alle richtig beantworten...
                </p>
                <p>
                    Los geht’s… viel Erfolg und viel Spaß!
                </p>
                <h5>Bitte gib den Code ein, den du erhalten hast und klicke dann auf „weiter“!</h5>
            </b-col>
        </b-row>

        <b-row class="justify-content-center">
            <b-form @submit.prevent="$emit('token', token)">
                <b-form-group>
                    <b-form-input type="text" size="lg" v-model="token" autocomplete="off" style="text-align:center; text-transform:uppercase"></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-button type="submit" size="lg" variant="primary" :disabled="!token">weiter</b-button>
                </b-form-group>
            </b-form>
        </b-row>
    </div>
</template>
<script>
export default {
  name: 'LoginToken',
  props: ['trial'],
  data: function () {
    return {
      token: ''
    }
  },

  watch: {
    token: function () {
      this.$emit('change')
    }
  },

  mounted: function () {
    this.token=this.trial
  }
}
</script>
